import axios from 'axios'
import { Toast } from 'vant'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://www.zhian-api.com/api', // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5 * 60 * 1000 // request timeout, 单位 ms, 暂定 5 分钟
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (localStorage.getItem('za_token')) {
      config.headers['Authorization'] = localStorage.getItem('za_token')
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // token 过期，自动刷新后在响应头中返回新的 token
    const headers = response.headers
    // 处理下载文件
    if (headers && (headers['content-type'] === 'application/x-msdownload' || headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      downloadBlob(response)
      return
    }

    // if (response.status === 401) {
    //   store.dispatch('user/resetToken').then(() => {
    //     location.reload()
    //   })
    // }
    if (headers.authorization !== undefined && headers.authorization !== '') {
      localStorage.setItem('za_token', headers.authorization)
    }

    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000 || res.data.code !== 0) {
      Toast(res.data.msg || res.msg || 'error')

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.data.code === 1403 || res.code === 401 || res.code === 50014) {
        this.$router.push('/')
      }
      return Promise.reject(res.data.msg || res.message || 'error')
    } else {
      return res
    }
  },
  error => {
    if (error.response && error.response.status === 401) {
      Toast('登录失效，请重新登录')
      localStorage.removeItem('za_token')
      location.href = '/'
      return Promise.reject(error)
    }

    Toast(error.msg || error.message)
    return Promise.reject(error)
  }
)
// download url
const downloadBlob = res => {
  const blob = new Blob([res.data])
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, res.headers.filename)
  } else {
    const link = document.createElement('a')
    const evt = document.createEvent('HTMLEvents')
    evt.initEvent('click', false, false)
    link.href = URL.createObjectURL(blob)
    link.download = decodeURIComponent(res.headers.filename)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(link.href)
  }
}

export default service
