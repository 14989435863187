// createWebHashHistory 是hash模式就是访问链接带有#
// createWebHistory  是history模式
import { createRouter, createWebHashHistory } from 'vue-router'

// 引入文件，动态路由
const Login = () => import("@/views/login/index.vue");
const Layout = () => import("@/components/layout/index.vue");
const Home = () => import("@/views/home/index.vue");
const Scan = () => import("@/views/scan/index.vue");
const AirCondition = () => import("@/views/airCondition/index.vue");
const Reimbursements = () => import("@/views/reimbursements/index.vue");
const Quotation = () => import("@/views/quotation/index.vue");
const Inventories = () => import("@/views/inventories/index.vue");
const SupplierCompany = () => import("@/views/manage/supplierCompany.vue");
const SupplierUser = () => import("@/views/manage/supplierUser.vue");
const Electrical = () => import("@/views/electrical/index.vue");
const Statistics = () => import("@/views/statistics/index.vue");
const Feedback = () => import("@/views/feedback/index.vue");
const Datacompare = () => import("@/views/datacompare/index.vue");
const Warning = () => import("@/views/warning/index.vue");
const Ordersend = () => import("@/views/ordersend/index.vue");
const Screen = () => import("@/views/screen/index.vue");
const List = () => import("@/views/screen/list.vue");
const MapOverview = () => import("@/views/mapOverview/index.vue");
const AirCondtionDetail = () => import("@/views/airCondition/detail");
const ReportList = () => import("@/views/reportList/index.vue")
const AirVrv = () => import('@/views/airCondition/components/vrv');

const routes = [
    {
        path: "/",
        redirect: "/login"
    },
    {
        // 登录路由
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        // 登入后主页
        path: "/layout",
        name: "layout",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "home",
                component: Home,
            },
            {
                path: "/scan",
                name: "scan",
                component: Scan
            },
            {
                path: "/air-condition",
                name: "air_condition",
                component: AirCondition
            },
        ]
    },
    {
        path: "/air-condition-detail",
        name: "air_condition-detail",
        component: AirCondtionDetail
    },
    // home
    {
        path: "/reimbursements",
        name: "reimbursements",
        component: Reimbursements,
    },
    {
        path: "/quotation",
        name: "quotation",
        component: Quotation,
    },
    {
        path: "/reportList",
        name: "reportList",
        component: ReportList,
    },
    {
        path: "/inventories",
        name: "inventories",
        component: Inventories,
    },
    {
        path: "/supplierCompany",
        name: "supplierCompany",
        component: SupplierCompany,
    },
    {
        path: "/supplierUser",
        name: "supplierUser",
        component: SupplierUser,
    },
    {
        path: "/electrical",
        name: "electrical",
        component: Electrical,
    },
    {
        path: "/statistics",
        name: "statistics",
        component: Statistics,
    },
    {
        path: "/feedback",
        name: "feedback",
        component: Feedback,
    },
    {
        path: "/datacompare",
        name: "datacompare",
        component: Datacompare,
    },
    {
        path: "/warning",
        name: "warning",
        component: Warning,
    },
    {
        path: "/ordersend",
        name: "ordersend",
        component: Ordersend,
    },
    {
        path: "/screen",
        name: "screen",
        component: Screen,
    },
    {
        path: "/list",
        name: "list",
        component: List,
    },
    {
        path: "/mapOverview",
        name: "mapOverview",
        component: MapOverview,
    },
    {
        path: '/airVrv',
        name: 'airVrv',
        component: AirVrv
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
