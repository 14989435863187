import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    Image as VanImage,
    Calendar,
    DatetimePicker,
    CheckboxGroup,
    Checkbox,
    Loading,
    Tag,
    Picker,
    Toast,
    RadioGroup,
    Radio,
    Uploader,
    Popup,
    Button,
    Grid,
    GridItem,
    Form,
    Field,
    Tabbar,
    TabbarItem,
    Icon,
    NavBar,
    DropdownMenu,
    DropdownItem,
    List,
    Cell,
    Col,
    Row,
    Tab,
    Tabs,
    Switch,
    Slider,
    Dialog,
    CellGroup,
    ActionSheet,
    Notify
} from 'vant'
import 'vant/lib/index.css';
import "@nutui/nutui/dist/style.css";
import { Menu, MenuItem } from '@nutui/nutui';


createApp(App)
    .use(Calendar)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Tab)
    .use(Tabs)
    .use(Col)
    .use(Row)
    .use(Popup)
    .use(Tag)
    .use(DatetimePicker)
    .use(Loading)
    .use(Picker)
    .use(Toast)
    .use(RadioGroup)
    .use(Radio)
    .use(Uploader)
    .use(VanImage)
    .use(Grid)
    .use(GridItem)
    .use(Cell)
    .use(List)
    .use(Icon)
    .use(Button)
    .use(Tabbar)
    .use(TabbarItem)
    .use(router)
    .use(store)
    .use(Form)
    .use(Field)
    .use(NavBar)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Slider)
    .use(Switch)
    .use(Dialog)
    .use(ActionSheet)
    .use(CellGroup)
    .use(Notify)
    .use(Menu)
    .use(MenuItem)
    .mount('#app')


// import { createApp } from "vue"
// import App from "./App.vue"
// const app = createApp(App)
// app.mount("#app")

// import { Button, Field } from 'vant'
// app.use(Button)
// app.use(Field)
