const state = {
    led_info: {}
}

const mutations = {
    ADD_LED_INFO: (state, info) => {
        state.led_info = info;
    },
    // CLEAR_LED_INFO:(state) => {
    //     state.led_info = {};
    // },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}